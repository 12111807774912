import { useQuery, QueryClient, useMutation } from "@tanstack/vue-query"
import { MaybeRef } from "@tanstack/vue-query/build/lib/types"

import { CoursesService } from "@/api/generated"
import { useCoursesStore } from "@/stores/courses"
import { unref } from "vue"
import { getResponseBody } from "../utils"
import { pubsubSubscriber } from "@/communication/pubsub"

export function useGetSubjectGroupQuery(subject_group_slug: MaybeRef<string>) {
  return useQuery({
    queryKey: ["subject_group", subject_group_slug],
    queryFn: () => getResponseBody(CoursesService.subjectGroupRetrieve(unref(subject_group_slug))),
  })
}

export function useGetPlatformSubjectsQuery() {
  return useGetSubjectGroupQuery("maturalne")
}

export function useGetSubjectQuery(subject_slug: MaybeRef<string>) {
  return useQuery({
    queryKey: ["subjects", subject_slug, "detail"],
    queryFn: () => getResponseBody(CoursesService.subjectRetrieve(unref(subject_slug))),
  })
}

export function useGetCurrentSubjectQuery() {
  const coursesStore = useCoursesStore()
  return useGetSubjectQuery(coursesStore.currentSubject as string) //TODO: fix & make reactive
}

export function useGetUnitsQuery(subject_slug: MaybeRef<string>) {
  return useQuery({
    queryKey: ["subjects", subject_slug, "units"],
    queryFn: () => getResponseBody(CoursesService.subjectUnitsList(unref(subject_slug))),
  })
}

export function useGetUnitQuery(unit_uuid: MaybeRef<string>) {
  return useQuery({
    queryKey: ["units", unit_uuid, "details"],
    queryFn: () => getResponseBody(CoursesService.unitRetrieve(unref(unit_uuid))),
  })
}

export function useGetLessonsQuery(unit_uuid: MaybeRef<string>) {
  return useQuery({
    queryKey: ["units", unit_uuid, "lessons"],
    queryFn: () => getResponseBody(CoursesService.unitLessonsList(unref(unit_uuid))),
  })
}

export function useGetLessonQuery(lesson_uuid: MaybeRef<string>) {
  return useQuery({
    queryKey: ["lessons", lesson_uuid, "detail"],
    queryFn: () => getResponseBody(CoursesService.lessonRetrieve(unref(lesson_uuid))),
  })
}

export function useGetLessonContentsQuery(lesson_uuid: MaybeRef<string>) {
  return useQuery({
    queryKey: ["lessons", lesson_uuid, "contents"],
    queryFn: () => getResponseBody(CoursesService.lessonContentsList(unref(lesson_uuid))),
  })
}

export function refreshLessonStats(queryClient: QueryClient, lesson_uuid: string) {
  return queryClient.invalidateQueries({ queryKey: ["lessons", lesson_uuid, "contents"] })
}

export function refreshUnitLessonList(queryClient: QueryClient, unit_uuid: string) {
  return queryClient.invalidateQueries({ queryKey: ["units", unit_uuid, "lessons"] })
}

export function refreshSubjectUnitsList(queryClient: QueryClient, subject_uuid: string) {
  return queryClient.invalidateQueries({ queryKey: ["subjects"] }) //TODO: target specific subject
}

export function useGetSubjectCustomDataQuery(subject_slug: MaybeRef<string>) {
  return useQuery({
    queryKey: ["subjects", subject_slug, "custom_data"],
    queryFn: () => getResponseBody(CoursesService.coursesApiSubjectsCustomDataRetrieve(unref(subject_slug))),
  })
}

export async function submitSubjectTag(subject_slug: string, tag: string) {
  const custom_data = await getResponseBody(CoursesService.coursesApiSubjectsCustomDataRetrieve(unref(subject_slug)))
  custom_data.custom_data["tag"] = tag
  return getResponseBody(CoursesService.coursesApiSubjectsCustomDataCreate(subject_slug, custom_data))
}

export function useSubmitSubjectTagMutation(queryClient: QueryClient) {
  return useMutation({
    mutationFn: async ({ subject_slug, tag }: { subject_slug: string; tag: string }) => {
      const pubsubPromise = new Promise((resolve) =>
        pubsubSubscriber.subscribeToEventOnce("UserSubjectPermissionsApplied", resolve)
      )
      const submitSubjectTagPromise = submitSubjectTag(subject_slug, tag)
      await Promise.allSettled([pubsubPromise, submitSubjectTagPromise])
      return submitSubjectTagPromise
    },
    onSuccess: (_, { subject_slug }) => {
      queryClient.invalidateQueries({ queryKey: ["subjects", subject_slug] })
      // queryClient.invalidateQueries({ queryKey: ["subjects", subject_slug, "custom_data"] })
    },
  })
}

export function useSkipLessonMutation(lesson_uuid: MaybeRef<string>) {
  return useMutation({
    mutationFn: () => {
      return CoursesService.lessonSkip(unref(lesson_uuid), {
        skipped: true,
      })
    },
  })
}
