/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `not-started` - NOT_STARTED
 * * `in-progress` - IN_PROGRESS
 * * `completed` - COMPLETED
 */
export enum ProgressEnum {
    NOT_STARTED = 'not-started',
    IN_PROGRESS = 'in-progress',
    COMPLETED = 'completed',
}
