/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `receipt` - receipt
 * * `vat` - vat
 */
export enum KindEnum {
    RECEIPT = 'receipt',
    VAT = 'vat',
}
