/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlterTasksInGroupDataRequest } from '../models/AlterTasksInGroupDataRequest';
import type { ChangeTaskTipsOrderRequest } from '../models/ChangeTaskTipsOrderRequest';
import type { ContentData } from '../models/ContentData';
import type { Task } from '../models/Task';
import type { TaskAttachment } from '../models/TaskAttachment';
import type { TaskAttachmentCreateRequest } from '../models/TaskAttachmentCreateRequest';
import type { TaskAttachmentDeleteRequest } from '../models/TaskAttachmentDeleteRequest';
import type { TaskCreateDataRequest } from '../models/TaskCreateDataRequest';
import type { TaskCreateResponse } from '../models/TaskCreateResponse';
import type { TaskData } from '../models/TaskData';
import type { TaskEditDataRequest } from '../models/TaskEditDataRequest';
import type { TaskFiterRequest } from '../models/TaskFiterRequest';
import type { TaskGroup } from '../models/TaskGroup';
import type { TaskGroupCreateData } from '../models/TaskGroupCreateData';
import type { TaskGroupCreateDataRequest } from '../models/TaskGroupCreateDataRequest';
import type { TaskGroupEditDataRequest } from '../models/TaskGroupEditDataRequest';
import type { TaskListData } from '../models/TaskListData';
import type { TaskStats } from '../models/TaskStats';
import type { TaskSubmitResponse } from '../models/TaskSubmitResponse';
import type { TaskTip } from '../models/TaskTip';
import type { TaskTipsCreateRequest } from '../models/TaskTipsCreateRequest';
import type { TaskTipUpdateRequest } from '../models/TaskTipUpdateRequest';
import type { TaskZip } from '../models/TaskZip';
import type { TaskZipRequest } from '../models/TaskZipRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TasksService {

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksAttachmentsDeleteCreate(
        requestBody: TaskAttachmentDeleteRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/admin_api/tasks/attachments/delete/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksGroupLinkUpdate(
        uuid: string,
        requestBody: AlterTasksInGroupDataRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tasks/admin_api/tasks/group/{uuid}/link/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TaskGroup
     * @throws ApiError
     */
    public static tasksAdminApiTasksGroupsList(): CancelablePromise<Array<TaskGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/admin_api/tasks/groups/',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksGroupsUpdate(
        uuid: string,
        requestBody: TaskGroupEditDataRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tasks/admin_api/tasks/groups/{uuid}/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksGroupsDestroy(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tasks/admin_api/tasks/groups/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param requestBody
     * @returns TaskGroupCreateData
     * @throws ApiError
     */
    public static tasksAdminApiTasksGroupsNewCreate(
        requestBody: TaskGroupCreateDataRequest,
    ): CancelablePromise<TaskGroupCreateData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/admin_api/tasks/groups/new/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns TaskListData
     * @throws ApiError
     */
    public static tasksAdminApiTasksListCreate(
        requestBody?: TaskFiterRequest,
    ): CancelablePromise<Array<TaskListData>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/admin_api/tasks/list/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns TaskCreateResponse
     * @throws ApiError
     */
    public static tasksAdminApiTasksNewCreate(
        requestBody: TaskCreateDataRequest,
    ): CancelablePromise<TaskCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/admin_api/tasks/new/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksTaskUpdate(
        uuid: string,
        requestBody: TaskEditDataRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tasks/admin_api/tasks/task/{uuid}/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksTaskDestroy(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/tasks/admin_api/tasks/task/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns TaskAttachment
     * @throws ApiError
     */
    public static tasksAdminApiTasksTaskAttachmentsList(
        uuid: string,
    ): CancelablePromise<Array<TaskAttachment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/admin_api/tasks/task/{uuid}/attachments/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksTaskAttachmentsCreate(
        uuid: string,
        requestBody: TaskAttachmentCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/admin_api/tasks/task/{uuid}/attachments/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns Task
     * @throws ApiError
     */
    public static tasksAdminApiTasksTaskDetailsRetrieve(
        uuid: string,
    ): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/admin_api/tasks/task/{uuid}/details/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns TaskTip
     * @throws ApiError
     */
    public static tasksAdminApiTasksTaskTipsList(
        uuid: string,
    ): CancelablePromise<Array<TaskTip>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/admin_api/tasks/task/{uuid}/tips/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksTaskTipsCreate(
        uuid: string,
        requestBody: TaskTipsCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/admin_api/tasks/task/{uuid}/tips/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksTaskTipsOrderChangeUpdate(
        uuid: string,
        requestBody: ChangeTaskTipsOrderRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tasks/admin_api/tasks/task/{uuid}/tips/order/change/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksTipsUpdate(
        requestBody: TaskTipUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/tasks/admin_api/tasks/tips/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static tasksAdminApiTasksTipsDeleteCreate(
        requestBody: TaskTipUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/admin_api/tasks/tips/delete/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static tasksAdminApiTasksTypesRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/admin_api/tasks/types/',
        });
    }

    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static tasksApiTasksAnswerRetrieve(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/api/tasks/{uuid}/answer/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns TaskAttachment
     * @throws ApiError
     */
    public static tasksApiTasksAttachmentsList(
        uuid: string,
    ): CancelablePromise<Array<TaskAttachment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/api/tasks/{uuid}/attachments/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns ContentData
     * @throws ApiError
     */
    public static tasksApiTasksContentRetrieve(
        uuid: string,
    ): CancelablePromise<ContentData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/api/tasks/{uuid}/content/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static tasksApiTasksStartCreate(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/api/tasks/{uuid}/start/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns TaskStats
     * @throws ApiError
     */
    public static tasksApiTasksStatsRetrieve(
        uuid: string,
    ): CancelablePromise<TaskStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/api/tasks/{uuid}/stats/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns TaskSubmitResponse
     * @throws ApiError
     */
    public static tasksApiTasksSubmitCreate(
        uuid: string,
        requestBody?: any,
    ): CancelablePromise<TaskSubmitResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/api/tasks/{uuid}/submit/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns TaskTip
     * @throws ApiError
     */
    public static tasksApiTasksTipsList(
        uuid: string,
    ): CancelablePromise<Array<TaskTip>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/api/tasks/{uuid}/tips/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns TaskData
     * @throws ApiError
     */
    public static tasksApiTasksGroupTasksList(
        uuid: string,
    ): CancelablePromise<Array<TaskData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tasks/api/tasks/group/{uuid}/tasks/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param requestBody
     * @returns TaskZip
     * @throws ApiError
     */
    public static tasksZipAdminApiUploadCreate(
        requestBody: TaskZipRequest,
    ): CancelablePromise<TaskZip> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tasks/zip/admin_api/upload/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
