import { tests } from '../scripts/abtest'

const setupCookies = () => {
  tests.forEach(test => {
    if (getCookie(test.name) !== null) return
    const final = parseInt(Math.random() * 2) >= 1
    setCookie(test.name, final, test.expiration)
  })
}

const setCookie = (cname, cvalue, expiration) => {
  const d = new Date(expiration)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = 'abtest-' + cname + '=' + cvalue + ';' + expires + ';path=/'
}

const getCookie = (cname) => {
  const name = 'abtest-' + cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length) === 'true'
    }
  }
  return null
}

export { getCookie, setCookie, setupCookies }
