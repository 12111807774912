import { pubsubSubscriber } from "./pubsub"
import { QueryClient } from "@tanstack/vue-query"
import { refreshUnitLessonList, refreshSubjectUnitsList } from "@/queries/courses"
import { queryClient } from "@/queries/client"

type LessonStatsUpdatedMessage = {
  type: string
  uuid: string
  unit_uuid: string
  subject_uuid: string
  timestamp: string
}

const invalidateUnitsAndLessons = async (message: LessonStatsUpdatedMessage) => {
  refreshUnitLessonList(queryClient, message.unit_uuid)
  refreshSubjectUnitsList(queryClient, message.subject_uuid)
}

export const subscribeToGlobalEvents = () => {
  console.log("subscribeToGlobalEvents")
  pubsubSubscriber.subscribeToEvent("LessonStatsUpdated", invalidateUnitsAndLessons)
}
