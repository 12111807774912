/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArticleText } from '../models/ArticleText';
import type { InteractiveQuestionAnswerInput } from '../models/InteractiveQuestionAnswerInput';
import type { InteractiveQuestionAnswerInputRequest } from '../models/InteractiveQuestionAnswerInputRequest';
import type { InteractiveQuestionAnswerOutput } from '../models/InteractiveQuestionAnswerOutput';
import type { LessonAddContentInputDTORequest } from '../models/LessonAddContentInputDTORequest';
import type { LessonAddContentResult } from '../models/LessonAddContentResult';
import type { LessonAdminData } from '../models/LessonAdminData';
import type { LessonContent } from '../models/LessonContent';
import type { LessonContentData } from '../models/LessonContentData';
import type { LessonCreateResult } from '../models/LessonCreateResult';
import type { LessonData } from '../models/LessonData';
import type { LessonEditDataRequest } from '../models/LessonEditDataRequest';
import type { LessonEditResult } from '../models/LessonEditResult';
import type { LessonSkipRequestRequest } from '../models/LessonSkipRequestRequest';
import type { NextPreviousSthResult } from '../models/NextPreviousSthResult';
import type { SetReadStatusInputDTO } from '../models/SetReadStatusInputDTO';
import type { SetReadStatusInputDTORequest } from '../models/SetReadStatusInputDTORequest';
import type { SetWatchStatusInputDTO } from '../models/SetWatchStatusInputDTO';
import type { SetWatchStatusInputDTORequest } from '../models/SetWatchStatusInputDTORequest';
import type { SetWatchTimeInputDTO } from '../models/SetWatchTimeInputDTO';
import type { SetWatchTimeInputDTORequest } from '../models/SetWatchTimeInputDTORequest';
import type { SubjectAdminData } from '../models/SubjectAdminData';
import type { SubjectCreateResult } from '../models/SubjectCreateResult';
import type { SubjectData } from '../models/SubjectData';
import type { SubjectEditDataRequest } from '../models/SubjectEditDataRequest';
import type { SubjectEditResult } from '../models/SubjectEditResult';
import type { SubjectGroupAdminSimplifiedData } from '../models/SubjectGroupAdminSimplifiedData';
import type { SubjectGroupCreateDataRequest } from '../models/SubjectGroupCreateDataRequest';
import type { SubjectGroupCreateResult } from '../models/SubjectGroupCreateResult';
import type { SubjectGroupData } from '../models/SubjectGroupData';
import type { UnitAdminData } from '../models/UnitAdminData';
import type { UnitCreateResult } from '../models/UnitCreateResult';
import type { UnitData } from '../models/UnitData';
import type { UnitEditDataRequest } from '../models/UnitEditDataRequest';
import type { UnitEditResult } from '../models/UnitEditResult';
import type { UnitFileUpload } from '../models/UnitFileUpload';
import type { UnitFileUploadRequest } from '../models/UnitFileUploadRequest';
import type { UserSubjectData } from '../models/UserSubjectData';
import type { UserSubjectDataRequest } from '../models/UserSubjectDataRequest';
import type { VideoData } from '../models/VideoData';
import type { VideoInteractiveQuestionBlock } from '../models/VideoInteractiveQuestionBlock';
import type { VideoStats } from '../models/VideoStats';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CoursesService {

    /**
     * @param lessonUuid
     * @returns LessonContent
     * @throws ApiError
     */
    public static coursesAdminApiLessonsContentsList(
        lessonUuid: string,
    ): CancelablePromise<Array<LessonContent>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/admin_api/lessons/{lesson_uuid}/contents/',
            path: {
                'lesson_uuid': lessonUuid,
            },
        });
    }

    /**
     * @param lessonUuid
     * @param requestBody
     * @returns LessonAddContentResult
     * @throws ApiError
     */
    public static coursesAdminApiLessonsContentsAddCreate(
        lessonUuid: string,
        requestBody: LessonAddContentInputDTORequest,
    ): CancelablePromise<LessonAddContentResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/admin_api/lessons/{lesson_uuid}/contents/add/',
            path: {
                'lesson_uuid': lessonUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SubjectGroupAdminSimplifiedData
     * @throws ApiError
     */
    public static coursesAdminApiSubjectGroupsList(): CancelablePromise<Array<SubjectGroupAdminSimplifiedData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/admin_api/subject_groups/',
        });
    }

    /**
     * @param subjectGroupUuid
     * @returns SubjectAdminData
     * @throws ApiError
     */
    public static coursesAdminApiSubjectGroupsSubjectsList(
        subjectGroupUuid: string,
    ): CancelablePromise<Array<SubjectAdminData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/admin_api/subject_groups/{subject_group_uuid}/subjects/',
            path: {
                'subject_group_uuid': subjectGroupUuid,
            },
        });
    }

    /**
     * @param subjectGroupUuid
     * @param requestBody
     * @returns SubjectCreateResult
     * @throws ApiError
     */
    public static coursesAdminApiSubjectGroupsSubjectsCreateCreate(
        subjectGroupUuid: string,
        requestBody?: SubjectEditDataRequest,
    ): CancelablePromise<SubjectCreateResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/admin_api/subject_groups/{subject_group_uuid}/subjects/create/',
            path: {
                'subject_group_uuid': subjectGroupUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns SubjectGroupCreateResult
     * @throws ApiError
     */
    public static coursesAdminApiSubjectGroupsCreateCreate(
        requestBody: SubjectGroupCreateDataRequest,
    ): CancelablePromise<SubjectGroupCreateResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/admin_api/subject_groups/create/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param subjectUuid
     * @returns string
     * @throws ApiError
     */
    public static coursesAdminApiSubjectsConfigurationRetrieve(
        subjectUuid: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/admin_api/subjects/{subject_uuid}/configuration/',
            path: {
                'subject_uuid': subjectUuid,
            },
        });
    }

    /**
     * @param subjectUuid
     * @param requestBody
     * @returns SubjectEditResult
     * @throws ApiError
     */
    public static coursesAdminApiSubjectsEditCreate(
        subjectUuid: string,
        requestBody?: SubjectEditDataRequest,
    ): CancelablePromise<SubjectEditResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/admin_api/subjects/{subject_uuid}/edit/',
            path: {
                'subject_uuid': subjectUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param subjectUuid
     * @returns UnitAdminData
     * @throws ApiError
     */
    public static coursesAdminApiSubjectsUnitsList(
        subjectUuid: string,
    ): CancelablePromise<Array<UnitAdminData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/admin_api/subjects/{subject_uuid}/units/',
            path: {
                'subject_uuid': subjectUuid,
            },
        });
    }

    /**
     * @param subjectUuid
     * @param requestBody
     * @returns UnitCreateResult
     * @throws ApiError
     */
    public static coursesAdminApiSubjectsUnitsCreateCreate(
        subjectUuid: string,
        requestBody?: UnitEditDataRequest,
    ): CancelablePromise<UnitCreateResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/admin_api/subjects/{subject_uuid}/units/create/',
            path: {
                'subject_uuid': subjectUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param subjectUuid
     * @param requestBody
     * @returns UnitEditResult
     * @throws ApiError
     */
    public static coursesAdminApiSubjectsUnitsEditUpdate(
        subjectUuid: string,
        requestBody: Array<UnitEditDataRequest>,
    ): CancelablePromise<Array<UnitEditResult>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/courses/admin_api/subjects/{subject_uuid}/units/edit/',
            path: {
                'subject_uuid': subjectUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param unitUuid
     * @param formData
     * @returns UnitFileUpload
     * @throws ApiError
     */
    public static coursesAdminApiSubjectsUnitsFilesCreate(
        unitUuid: string,
        formData?: UnitFileUploadRequest,
    ): CancelablePromise<UnitFileUpload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/admin_api/subjects/{unit_uuid}/units/files/',
            path: {
                'unit_uuid': unitUuid,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param unitUuid
     * @returns LessonAdminData
     * @throws ApiError
     */
    public static coursesAdminApiUnitsLessonsList(
        unitUuid: string,
    ): CancelablePromise<Array<LessonAdminData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/admin_api/units/{unit_uuid}/lessons/',
            path: {
                'unit_uuid': unitUuid,
            },
        });
    }

    /**
     * @param unitUuid
     * @param requestBody
     * @returns LessonCreateResult
     * @throws ApiError
     */
    public static coursesAdminApiUnitsLessonsCreateCreate(
        unitUuid: string,
        requestBody?: LessonEditDataRequest,
    ): CancelablePromise<LessonCreateResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/admin_api/units/{unit_uuid}/lessons/create/',
            path: {
                'unit_uuid': unitUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param unitUuid
     * @param requestBody
     * @returns LessonEditResult
     * @throws ApiError
     */
    public static coursesAdminApiUnitsLessonsEditUpdate(
        unitUuid: string,
        requestBody: Array<LessonEditDataRequest>,
    ): CancelablePromise<Array<LessonEditResult>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/courses/admin_api/units/{unit_uuid}/lessons/edit/',
            path: {
                'unit_uuid': unitUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns SetReadStatusInputDTO
     * @throws ApiError
     */
    public static coursesApiContentArticleSetReadStatusCreate(
        uuid: string,
        requestBody: SetReadStatusInputDTORequest,
    ): CancelablePromise<SetReadStatusInputDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/api/content/{uuid}/article/set_read_status/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns ArticleText
     * @throws ApiError
     */
    public static coursesApiContentArticleTextRetrieve(
        uuid: string,
    ): CancelablePromise<ArticleText> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/content/{uuid}/article/text/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns VideoData
     * @throws ApiError
     */
    public static coursesApiContentVideoContentRetrieve(
        uuid: string,
    ): CancelablePromise<VideoData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/content/{uuid}/video/content/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns VideoInteractiveQuestionBlock
     * @throws ApiError
     */
    public static coursesApiContentVideoQuestionsList(
        uuid: string,
    ): CancelablePromise<Array<VideoInteractiveQuestionBlock>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/content/{uuid}/video/questions/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns InteractiveQuestionAnswerInput
     * @throws ApiError
     */
    public static coursesApiContentVideoQuestionsCurrentAnswersList(
        uuid: string,
    ): CancelablePromise<Array<InteractiveQuestionAnswerInput>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/content/{uuid}/video/questions/current_answers/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns InteractiveQuestionAnswerOutput
     * @throws ApiError
     */
    public static coursesApiContentVideoQuestionsScoreAnswersCreate(
        uuid: string,
        requestBody: Array<InteractiveQuestionAnswerInputRequest>,
    ): CancelablePromise<Array<InteractiveQuestionAnswerOutput>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/api/content/{uuid}/video/questions/score_answers/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns SetWatchStatusInputDTO
     * @throws ApiError
     */
    public static coursesApiContentVideoSetWatchStatusCreate(
        uuid: string,
        requestBody: SetWatchStatusInputDTORequest,
    ): CancelablePromise<SetWatchStatusInputDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/api/content/{uuid}/video/set_watch_status/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns SetWatchTimeInputDTO
     * @throws ApiError
     */
    public static coursesApiContentVideoSetWatchTimeCreate(
        uuid: string,
        requestBody: SetWatchTimeInputDTORequest,
    ): CancelablePromise<SetWatchTimeInputDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/api/content/{uuid}/video/set_watch_time/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns VideoStats
     * @throws ApiError
     */
    public static coursesApiContentVideoStatsRetrieve(
        uuid: string,
    ): CancelablePromise<VideoStats> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/content/{uuid}/video/stats/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns LessonData
     * @throws ApiError
     */
    public static lessonRetrieve(
        uuid: string,
    ): CancelablePromise<LessonData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/lessons/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns LessonContentData
     * @throws ApiError
     */
    public static lessonContentsList(
        uuid: string,
    ): CancelablePromise<Array<LessonContentData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/lessons/{uuid}/contents/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns NextPreviousSthResult
     * @throws ApiError
     */
    public static coursesApiLessonsNextRetrieve(
        uuid: string,
    ): CancelablePromise<NextPreviousSthResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/lessons/{uuid}/next/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns NextPreviousSthResult
     * @throws ApiError
     */
    public static coursesApiLessonsNextNotDoneRetrieve(
        uuid: string,
    ): CancelablePromise<NextPreviousSthResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/lessons/{uuid}/next_not_done/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns NextPreviousSthResult
     * @throws ApiError
     */
    public static coursesApiLessonsPreviousRetrieve(
        uuid: string,
    ): CancelablePromise<NextPreviousSthResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/lessons/{uuid}/previous/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static lessonSkip(
        uuid: string,
        requestBody: LessonSkipRequestRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/api/lessons/{uuid}/skip/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param slug
     * @returns SubjectGroupData
     * @throws ApiError
     */
    public static subjectGroupRetrieve(
        slug: string,
    ): CancelablePromise<SubjectGroupData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/subject_groups/{slug}/',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * @param slug
     * @returns SubjectGroupData
     * @throws ApiError
     */
    public static subjectGroupPublicRetrieve(
        slug: string,
    ): CancelablePromise<SubjectGroupData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/subject_groups/{slug}/public/',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * @param slug
     * @returns SubjectData
     * @throws ApiError
     */
    public static subjectRetrieve(
        slug: string,
    ): CancelablePromise<SubjectData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/subjects/{slug}/',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * @param slug
     * @returns UserSubjectData
     * @throws ApiError
     */
    public static coursesApiSubjectsCustomDataRetrieve(
        slug: string,
    ): CancelablePromise<UserSubjectData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/subjects/{slug}/custom_data/',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * @param slug
     * @param requestBody
     * @returns UserSubjectData
     * @throws ApiError
     */
    public static coursesApiSubjectsCustomDataCreate(
        slug: string,
        requestBody: UserSubjectDataRequest,
    ): CancelablePromise<UserSubjectData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/courses/api/subjects/{slug}/custom_data/',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param slug
     * @returns UnitData
     * @throws ApiError
     */
    public static subjectUnitsList(
        slug: string,
    ): CancelablePromise<Array<UnitData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/subjects/{slug}/units/',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * @param uuid
     * @returns UnitData
     * @throws ApiError
     */
    public static unitRetrieve(
        uuid: string,
    ): CancelablePromise<UnitData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/units/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns LessonData
     * @throws ApiError
     */
    public static unitLessonsList(
        uuid: string,
    ): CancelablePromise<Array<LessonData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/units/{uuid}/lessons/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns NextPreviousSthResult
     * @throws ApiError
     */
    public static coursesApiUnitsNextRetrieve(
        uuid: string,
    ): CancelablePromise<NextPreviousSthResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/units/{uuid}/next/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns NextPreviousSthResult
     * @throws ApiError
     */
    public static coursesApiUnitsNextNotDoneRetrieve(
        uuid: string,
    ): CancelablePromise<NextPreviousSthResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/units/{uuid}/next_not_done/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns NextPreviousSthResult
     * @throws ApiError
     */
    public static coursesApiUnitsPreviousRetrieve(
        uuid: string,
    ): CancelablePromise<NextPreviousSthResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/courses/api/units/{uuid}/previous/',
            path: {
                'uuid': uuid,
            },
        });
    }

}
