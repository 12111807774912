/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTagGroupInputDTORequest } from '../models/CreateTagGroupInputDTORequest';
import type { CreateTagInputDTORequest } from '../models/CreateTagInputDTORequest';
import type { TagGroup } from '../models/TagGroup';
import type { TagGroupBasic } from '../models/TagGroupBasic';
import type { TagSimplified } from '../models/TagSimplified';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagsService {

    /**
     * @param mctUuid
     * @returns TagSimplified
     * @throws ApiError
     */
    public static tagsAllowedTagsList(
        mctUuid: string,
    ): CancelablePromise<Array<TagSimplified>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tags/allowed_tags/{mct_uuid}/',
            path: {
                'mct_uuid': mctUuid,
            },
        });
    }

    /**
     * @returns TagGroupBasic
     * @throws ApiError
     */
    public static tagsGroupsList(): CancelablePromise<Array<TagGroupBasic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tags/groups/',
        });
    }

    /**
     * @param requestBody
     * @returns TagGroupBasic
     * @throws ApiError
     */
    public static tagsGroupsCreate(
        requestBody: CreateTagGroupInputDTORequest,
    ): CancelablePromise<TagGroupBasic> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tags/groups/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param tagGroupUuid
     * @returns TagGroup
     * @throws ApiError
     */
    public static tagsGroupsRetrieve(
        tagGroupUuid: string,
    ): CancelablePromise<TagGroup> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/tags/groups/{tag_group_uuid}/',
            path: {
                'tag_group_uuid': tagGroupUuid,
            },
        });
    }

    /**
     * @param tagGroupUuid
     * @param requestBody
     * @returns TagSimplified
     * @throws ApiError
     */
    public static tagsGroupsNewTagCreate(
        tagGroupUuid: string,
        requestBody: CreateTagInputDTORequest,
    ): CancelablePromise<TagSimplified> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/tags/groups/{tag_group_uuid}/new_tag/',
            path: {
                'tag_group_uuid': tagGroupUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
