import { getCookie } from './abtests'

const showOnScroll = {
  inViewport (el) {
    var rect = el.getBoundingClientRect()
    return !(
      rect.bottom < 0 ||
      rect.right < 0 ||
      rect.left > window.innerWidth ||
      rect.top > window.innerHeight
    )
  },
  bind (el, binding) {
    el.$onScroll = function () {
      if (binding.def.inViewport(el)) {
        el.classList.add(binding.value)
        binding.def.unbind(el, binding)
      }
    }
    document.addEventListener('scroll', el.$onScroll)
  },

  inserted (el, binding) {
    el.$onScroll()
  },

  unbind (el, binding) {
    document.removeEventListener('scroll', el.$onScroll)
    delete el.$onScroll
  }
}

const animationDelay = {
  bind (el, binding) {
    el.style.animationDelay = binding.value + 's'
  }
}

// Example use-case:
// v-abtest:if="{name: 'test'}"
// v-abtest:class="{name: 'test', class: 'red'}"
// type options: if, else, class
const abTests = {
  inserted (el, binding) {
    const type = binding.arg
    const value = binding.value

    let result = getCookie(value.name)

    if (result === null && type === 'else') {
      el.remove()
    }

    if (result === null) return

    result = (result === 'true') || result

    if (type === 'class' && result) {
      el.classList.add(`ab-test-${value.class}`)
    } else if ((type === 'if' && !result) || (type === 'else' && result)) {
      el.remove()
    }
  }
}

export { showOnScroll, animationDelay, abTests }
