let isOpen = false

function patchTawk () {
  const interval = setInterval(() => {
    const tawkDocument = document.querySelector('[title="chat widget"]')?.contentWindow?.document
    const tawkButton = tawkDocument?.querySelector('.tawk-button')
    if (!tawkButton) {
      return
    }
    clearInterval(interval)

    addAriaLabel(tawkButton)
    addFocusStyles(tawkDocument)
    addClickListener(tawkButton)
  }, 100)
}

function addAriaLabel (button) {
  button.setAttribute('aria-label', 'Pokaź/ukryj czat')
}

function addFocusStyles (tawkDocument) {
  const tawkStyleCSS = `
  .tawk-button:focus-visible {
    border: 4px solid #66DDAA !important;
  }
`
  const tawkStyle = document.createElement('style')
  tawkStyle.textContent = tawkStyleCSS
  tawkDocument.querySelector('head').appendChild(tawkStyle)
}

function addClickListener (button) {
  button.addEventListener('click', function () {
    isOpen = !isOpen
    window.ym?.(70925977, 'reachGoal', 'tawkto', { action: isOpen ? 'open' : 'close' })
  })
}

export { patchTawk }
