import { defineStore } from "pinia"

import { SubjectData } from "@/api/generated"
import { useGetSubjectQuery } from "@/queries/courses"
import { computed } from "vue"
import { MaybeRef } from "@tanstack/vue-query/build/lib/types"

export type RootState = {
  // subjects: SubjectStats[];
  currentSubject?: string
}

export function hasLessonSupertesterAccess(lesson_uuid: string) {
  return false
}

export function hasSubjectAccessBySlug(subject_slug: MaybeRef<string>) {
  const getSubjectQuery = useGetSubjectQuery(subject_slug)
  return computed(() => {
    const data = getSubjectQuery.data.value
    if (data) {
      return hasSubjectAccess(data)
    } else {
      return null
    }
  })
}

export function hasSubjectAccess(subject: SubjectData) {
  if (!subject.stats) {
    return false
  }

  if (!subject.stats.end_date) {
    return false
  }

  const end_date = new Date(subject.stats.end_date)
  end_date.setHours(0, 0, 0, 0)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  return end_date >= today
}

export const useCoursesStore = defineStore("courses", {
  state: (): RootState => {
    return {
      currentSubject: undefined,
    }
  },
  getters: {},
  actions: {},
})
