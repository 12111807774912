import { PubSubCallback } from "@/types/communication"

class PubSubSubscriber {
  private websocket: WebSocket | null = null
  private subscribers: Map<string, Array<PubSubCallback>> = new Map()

  private eventHandler(event: MessageEvent<any>) {
    const data = JSON.parse(event.data)
    this.subscribers.get(data["type"])?.forEach((callback) => Promise.resolve(data).then(callback))
  }

  close() {
    this.websocket?.close()
    this.websocket = null
  }

  connect(url: string) {
    this.close()
    this.websocket = new WebSocket(url)
    this.websocket.onmessage = (event) => this.eventHandler(event)
  }

  subscribeToEvent(event: string, callback: PubSubCallback) {
    if (!this.subscribers.get(event)) {
      this.subscribers.set(event, [])
    }
    this.subscribers.get(event)?.push(callback)
  }

  subscribeToEventOnce(event: string, callback: PubSubCallback) {
    const callback_wrapper = (data: any) => {
      this.unsubscribeEvent(event, callback_wrapper)
      callback(data)
    }
    this.subscribeToEvent(event, callback_wrapper)
  }

  unsubscribeEvent(event: string, subscription_callback: PubSubCallback) {
    const subscribers = this.subscribers.get(event)
    if (subscribers !== undefined) {
      this.subscribers.set(
        event,
        subscribers.filter((callback) => callback !== subscription_callback)
      )
    }
  }
}

export const pubsubSubscriber = new PubSubSubscriber()
