import { QueryClient } from "@tanstack/vue-query"

const queryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
    },
  },
}

export const queryClient = new QueryClient(queryClientConfig)
