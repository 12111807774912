/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomPasswordResetConfirmRequest } from '../models/CustomPasswordResetConfirmRequest';
import type { CustomPasswordResetRequest } from '../models/CustomPasswordResetRequest';
import type { LoginRequest } from '../models/LoginRequest';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PasswordStrength } from '../models/PasswordStrength';
import type { ResendEmailVerificationRequest } from '../models/ResendEmailVerificationRequest';
import type { RestAuthDetail } from '../models/RestAuthDetail';
import type { SocialLogin } from '../models/SocialLogin';
import type { SocialLoginRequest } from '../models/SocialLoginRequest';
import type { Token } from '../models/Token';
import type { UserBaseDataRequest } from '../models/UserBaseDataRequest';
import type { UserProfile } from '../models/UserProfile';
import type { UserRegisterDataRequest } from '../models/UserRegisterDataRequest';
import type { UserUpdateData } from '../models/UserUpdateData';
import type { UserUpdateDataRequest } from '../models/UserUpdateDataRequest';
import type { VerifyEmailRequest } from '../models/VerifyEmailRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountService {

    /**
     * @param requestBody
     * @returns PasswordStrength
     * @throws ApiError
     */
    public static accountApiPasswordStrengthCreate(
        requestBody: UserBaseDataRequest,
    ): CancelablePromise<PasswordStrength> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/api/password/strength/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UserProfile
     * @throws ApiError
     */
    public static accountApiProfileRetrieve(): CancelablePromise<UserProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/api/profile/',
        });
    }

    /**
     * @returns string
     * @throws ApiError
     */
    public static accountApiPubsubUrlRetrieve(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/api/pubsub/url/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static accountApiUserDeleteCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/api/user/delete/',
        });
    }

    /**
     * @param requestBody
     * @returns UserUpdateData
     * @throws ApiError
     */
    public static accountApiUserUpdateUpdate(
        requestBody: UserUpdateDataRequest,
    ): CancelablePromise<UserUpdateData> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/account/api/user/update/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check the credentials and return the REST Token
     * if the credentials are valid and authenticated.
     * Calls Django Auth login method to register User ID
     * in Django session framework
     *
     * Accept the following POST parameters: username, password
     * Return the REST Framework Token Object's key.
     * @param requestBody
     * @returns Token
     * @throws ApiError
     */
    public static accountLoginCreate(
        requestBody: LoginRequest,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/login/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * class used for social authentications
     * example usage for facebook with access_token
     * -------------
     * from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter
     *
     * class FacebookLogin(SocialLoginView):
     * adapter_class = FacebookOAuth2Adapter
     * -------------
     *
     * example usage for facebook with code
     *
     * -------------
     * from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter
     * from allauth.socialaccount.providers.oauth2.client import OAuth2Client
     *
     * class FacebookLogin(SocialLoginView):
     * adapter_class = FacebookOAuth2Adapter
     * client_class = OAuth2Client
     * callback_url = 'localhost:8000'
     * -------------
     * @param requestBody
     * @returns SocialLogin
     * @throws ApiError
     */
    public static accountLoginFacebookCreate(
        requestBody?: SocialLoginRequest,
    ): CancelablePromise<SocialLogin> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/login/facebook/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * class used for social authentications
     * example usage for facebook with access_token
     * -------------
     * from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter
     *
     * class FacebookLogin(SocialLoginView):
     * adapter_class = FacebookOAuth2Adapter
     * -------------
     *
     * example usage for facebook with code
     *
     * -------------
     * from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter
     * from allauth.socialaccount.providers.oauth2.client import OAuth2Client
     *
     * class FacebookLogin(SocialLoginView):
     * adapter_class = FacebookOAuth2Adapter
     * client_class = OAuth2Client
     * callback_url = 'localhost:8000'
     * -------------
     * @param requestBody
     * @returns SocialLogin
     * @throws ApiError
     */
    public static accountLoginGithubCreate(
        requestBody?: SocialLoginRequest,
    ): CancelablePromise<SocialLogin> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/login/github/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * class used for social authentications
     * example usage for facebook with access_token
     * -------------
     * from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter
     *
     * class FacebookLogin(SocialLoginView):
     * adapter_class = FacebookOAuth2Adapter
     * -------------
     *
     * example usage for facebook with code
     *
     * -------------
     * from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter
     * from allauth.socialaccount.providers.oauth2.client import OAuth2Client
     *
     * class FacebookLogin(SocialLoginView):
     * adapter_class = FacebookOAuth2Adapter
     * client_class = OAuth2Client
     * callback_url = 'localhost:8000'
     * -------------
     * @param requestBody
     * @returns SocialLogin
     * @throws ApiError
     */
    public static accountLoginGoogleCreate(
        requestBody?: SocialLoginRequest,
    ): CancelablePromise<SocialLogin> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/login/google/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * class used for social authentications
     * example usage for facebook with access_token
     * -------------
     * from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter
     *
     * class FacebookLogin(SocialLoginView):
     * adapter_class = FacebookOAuth2Adapter
     * -------------
     *
     * example usage for facebook with code
     *
     * -------------
     * from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter
     * from allauth.socialaccount.providers.oauth2.client import OAuth2Client
     *
     * class FacebookLogin(SocialLoginView):
     * adapter_class = FacebookOAuth2Adapter
     * client_class = OAuth2Client
     * callback_url = 'localhost:8000'
     * -------------
     * @param requestBody
     * @returns SocialLogin
     * @throws ApiError
     */
    public static accountLoginLinkedinCreate(
        requestBody?: SocialLoginRequest,
    ): CancelablePromise<SocialLogin> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/login/linkedin/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Calls Django logout method and delete the Token object
     * assigned to the current User object.
     *
     * Accepts/Returns nothing.
     * @returns RestAuthDetail
     * @throws ApiError
     */
    public static accountLogoutCreate(): CancelablePromise<RestAuthDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/logout/',
        });
    }

    /**
     * Calls Django Auth SetPasswordForm save method.
     *
     * Accepts the following POST parameters: new_password1, new_password2
     * Returns the success/fail message.
     * @param requestBody
     * @returns RestAuthDetail
     * @throws ApiError
     */
    public static accountPasswordChangeCreate(
        requestBody: PasswordChangeRequest,
    ): CancelablePromise<RestAuthDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/password/change/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Calls Django Auth PasswordResetForm save method.
     *
     * Accepts the following POST parameters: email
     * Returns the success/fail message.
     * @param requestBody
     * @returns RestAuthDetail
     * @throws ApiError
     */
    public static accountPasswordResetCreate(
        requestBody: CustomPasswordResetRequest,
    ): CancelablePromise<RestAuthDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/password/reset/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Password reset e-mail link is confirmed, therefore
     * this resets the user's password.
     *
     * Accepts the following POST parameters: token, uid,
     * new_password1, new_password2
     * Returns the success/fail message.
     * @param requestBody
     * @returns RestAuthDetail
     * @throws ApiError
     */
    public static accountPasswordResetConfirmCreate(
        requestBody: CustomPasswordResetConfirmRequest,
    ): CancelablePromise<RestAuthDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/password/reset/confirm/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns RestAuthDetail
     * @throws ApiError
     */
    public static accountRegistrationCreate(
        requestBody: UserRegisterDataRequest,
    ): CancelablePromise<RestAuthDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/registration/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns RestAuthDetail
     * @throws ApiError
     */
    public static accountRegistrationResendEmailCreate(
        requestBody: ResendEmailVerificationRequest,
    ): CancelablePromise<RestAuthDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/registration/resend-email/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns RestAuthDetail
     * @throws ApiError
     */
    public static accountRegistrationVerifyEmailCreate(
        requestBody: VerifyEmailRequest,
    ): CancelablePromise<RestAuthDetail> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/registration/verify-email/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
