/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * * `1` - REQUIRED
 * * `2` - SKIPPABLE
 * * `3` - EXTRA
 */
export enum SkippableEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
}
