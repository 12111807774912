/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Card } from '../models/Card';
import type { CardRequest } from '../models/CardRequest';
import type { DeckCreate } from '../models/DeckCreate';
import type { DeckCreateRequest } from '../models/DeckCreateRequest';
import type { DeckListData } from '../models/DeckListData';
import type { DeckUpdateRequest } from '../models/DeckUpdateRequest';
import type { GameData } from '../models/GameData';
import type { PatchedDeckUpdateRequest } from '../models/PatchedDeckUpdateRequest';
import type { PracticeRequest } from '../models/PracticeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FlashcardsService {

    /**
     * @param identifier
     * @param mode
     * @returns GameData
     * @throws ApiError
     */
    public static flashcardsCardRetrieve(
        identifier: string,
        mode: string,
    ): CancelablePromise<GameData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/flashcards/{mode}/card/{identifier}',
            path: {
                'identifier': identifier,
                'mode': mode,
            },
        });
    }

    /**
     * @param identifier
     * @param mode
     * @param requestBody
     * @returns GameData
     * @throws ApiError
     */
    public static flashcardsCardCreate(
        identifier: string,
        mode: string,
        requestBody: PracticeRequest,
    ): CancelablePromise<GameData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/flashcards/{mode}/card/{identifier}',
            path: {
                'identifier': identifier,
                'mode': mode,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns Card
     * @throws ApiError
     */
    public static flashcardsCardCreateCreate(
        requestBody: CardRequest,
    ): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/flashcards/card/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this card.
     * @returns void
     * @throws ApiError
     */
    public static flashcardsCardManageDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/flashcards/card/manage/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns DeckCreate
     * @throws ApiError
     */
    public static flashcardsDeckAddCreate(
        requestBody: DeckCreateRequest,
    ): CancelablePromise<DeckCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/flashcards/deck/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this deck.
     * @returns any No response body
     * @throws ApiError
     */
    public static flashcardsDeckExportRetrieve(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/flashcards/deck/export/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param slug
     * @returns DeckListData
     * @throws ApiError
     */
    public static flashcardsDeckListRetrieve(
        slug: string,
    ): CancelablePromise<DeckListData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/flashcards/deck/list/{slug}',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this deck.
     * @returns void
     * @throws ApiError
     */
    public static flashcardsDeckManageDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/flashcards/deck/manage/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id A unique integer value identifying this deck.
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static flashcardsDeckUpdateUpdate(
        id: number,
        requestBody: DeckUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/flashcards/deck/update/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id A unique integer value identifying this deck.
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static flashcardsDeckUpdatePartialUpdate(
        id: number,
        requestBody?: PatchedDeckUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/flashcards/deck/update/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
