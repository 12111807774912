<template>
  <div id="app">
    <Cookies />
    <div id="kontener">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { setupCookies } from "./utils/abtests"

import { useAuthStore } from "./stores/auth"

export default defineComponent({
  components: {
    Cookies: () => import("@/components/others/Cookies.vue"),
  },
  setup() {
    const authStore = useAuthStore()

    return { authStore }
  },
  mounted() {
    setupCookies()

    this.authStore.initialize()
  },
  metaInfo() {
    return {
      title: "MaturaIT",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "MaturaIT to platforma e-learningowa przygotowująca do matury z informatyki, fizyki i matematyki. Zdobędziesz z nami niezbędną wiedzę, która zaowocuje wybitnym wynikiem.",
        },
      ],
    }
  },
})
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu:wght@400;500;700&display=swap");
@import "@/scss/global.scss";

#app {
  font-family: "Open Sans", "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#kontener {
  height: 100%;
  position: relative;
}
</style>
