/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiscountCodeCreateRequest } from '../models/DiscountCodeCreateRequest';
import type { DiscountCodeList } from '../models/DiscountCodeList';
import type { DiscountCodeUpdateRequest } from '../models/DiscountCodeUpdateRequest';
import type { DiscountCodeUse } from '../models/DiscountCodeUse';
import type { DiscountCodeUserData } from '../models/DiscountCodeUserData';
import type { FilterRequest } from '../models/FilterRequest';
import type { GroupFilterRequest } from '../models/GroupFilterRequest';
import type { ManualOrderCreateDataRequest } from '../models/ManualOrderCreateDataRequest';
import type { OrderData } from '../models/OrderData';
import type { ProductData } from '../models/ProductData';
import type { ProductDataAdmin } from '../models/ProductDataAdmin';
import type { ProductDataCreateRequest } from '../models/ProductDataCreateRequest';
import type { ProductDataUpdateRequest } from '../models/ProductDataUpdateRequest';
import type { ProductGroup } from '../models/ProductGroup';
import type { ProductGroupSlugRequest } from '../models/ProductGroupSlugRequest';
import type { ProductGroupUpdateRequest } from '../models/ProductGroupUpdateRequest';
import type { ProductsFileRequest } from '../models/ProductsFileRequest';
import type { TransactionDataRequest } from '../models/TransactionDataRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * @returns DiscountCodeList
     * @throws ApiError
     */
    public static productsAdminApiDiscountList(): CancelablePromise<Array<DiscountCodeList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/admin_api/discount/',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiDiscountUpdate(
        uuid: string,
        requestBody?: DiscountCodeUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/admin_api/discount/{uuid}/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiDiscountDestroy(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/products/admin_api/discount/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiDiscountNewCreate(
        requestBody: DiscountCodeCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/admin_api/discount/new/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DiscountCodeUse
     * @throws ApiError
     */
    public static productsAdminApiDiscountUseList(): CancelablePromise<Array<DiscountCodeUse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/admin_api/discount/use/',
        });
    }

    /**
     * @returns ProductGroup
     * @throws ApiError
     */
    public static productsAdminApiGroupsList(): CancelablePromise<Array<ProductGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/admin_api/groups/',
        });
    }

    /**
     * @param slug
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiGroupsUpdate(
        slug: string,
        requestBody: ProductGroupSlugRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/admin_api/groups/{slug}/',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param slug
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiGroupsDestroy(
        slug: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/products/admin_api/groups/{slug}/',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * @param slug
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiGroupsProductsAddUpdate(
        slug: string,
        requestBody: ProductGroupUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/admin_api/groups/{slug}/products/add/',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param slug
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiGroupsProductsRemoveUpdate(
        slug: string,
        requestBody: ProductGroupUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/admin_api/groups/{slug}/products/remove/',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiGroupsNewCreate(
        requestBody: ProductGroupSlugRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/admin_api/groups/new/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiProductCreate(
        requestBody: ProductDataCreateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/admin_api/product/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiProductUpdate(
        uuid: string,
        requestBody?: ProductDataUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/admin_api/product/{uuid}/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiProductDestroy(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/products/admin_api/product/{uuid}/',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ProductDataAdmin
     * @throws ApiError
     */
    public static productsAdminApiProductsCreate(
        requestBody?: FilterRequest,
    ): CancelablePromise<Array<ProductDataAdmin>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/admin_api/products/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiProductsExportCreate(
        requestBody: GroupFilterRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/admin_api/products/export/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiProductsImportCreate(
        requestBody: ProductsFileRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/admin_api/products/import/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiProductsOrderManualCreate(
        requestBody: ManualOrderCreateDataRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/admin_api/products/order/manual/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderData
     * @throws ApiError
     */
    public static productsAdminApiProductsOrdersCreate(
        requestBody: GroupFilterRequest,
    ): CancelablePromise<Array<OrderData>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/admin_api/products/orders/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static productsAdminApiProductsUpdateUpdate(
        requestBody: ProductsFileRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/products/admin_api/products/update/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param slug
     * @returns ProductData
     * @throws ApiError
     */
    public static productsApiGroupProductsList(
        slug: string,
    ): CancelablePromise<Array<ProductData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/api/group/{slug}/products/',
            path: {
                'slug': slug,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns string
     * @throws ApiError
     */
    public static productsApiPayCreate(
        uuid: string,
        requestBody: TransactionDataRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/products/api/pay/{uuid}/',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param code
     * @param uuid
     * @returns DiscountCodeUserData
     * @throws ApiError
     */
    public static productsApiProductDiscountCodeRetrieve(
        code: string,
        uuid: string,
    ): CancelablePromise<DiscountCodeUserData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/api/product/{uuid}/discount_code/{code}/',
            path: {
                'code': code,
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns ProductData
     * @throws ApiError
     */
    public static productsApiProductsList(): CancelablePromise<Array<ProductData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/products/api/products/',
        });
    }

}
